import React from "react";
import { graphql } from "gatsby";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  query TeamMemberTemplateQuery($id: String!) {
    teamMember: sanityTeamMember(id: { eq: $id }) {
      id
      seoTitle
      metaDescription
      name
      title
      workPhoneNumber
      cellPhoneNumber
      emailAddress
      linkedinProfile
      headshot {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      _rawBioBlock
      bio {
        listItem
      }
    }
    email: file(relativePath: { eq: "7.0 Profile Pages/email.svg" }) {
      publicURL
    }
    phone: file(relativePath: { eq: "7.0 Profile Pages/phone.svg" }) {
      publicURL
    }
    mobile: file(relativePath: { eq: "7.0 Profile Pages/mobile.svg" }) {
      publicURL
    }
    linkedin: file(relativePath: { eq: "7.0 Profile Pages/linkedin.svg" }) {
      publicURL
    }
  }
`;

const Template = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title={data.teamMember.seoTitle}
        description={data.teamMember.metaDescription}
        // openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
        // twitterOpenGraphImage={
        //   page.twitterCardImage && page.twitterCardImage.asset.url
        // }
      />

      <section className="pb-20 pt-7  md:pb-28 md:pt-16">
        <div className="container">
          <div className="grid gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="hidden md:block">
              {data.teamMember.headshot && (
                <div className="mb-6">
                  <GatsbyImage
                    image={data.teamMember.headshot.asset.gatsbyImageData}
                    alt={data.teamMember.name}
                    loading="eager"
                  />
                </div>
              )}
              <div className="space-y-4">
                {data.teamMember.workPhoneNumber && (
                  <div>
                    <a
                      href={`tel:${data.teamMember.workPhoneNumber
                        .replace(/[()]/g, "")
                        .replace(/ /g, "-")}`}
                      className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                    >
                      <img src={data.phone.publicURL} alt="Phone" />
                      <span>{data.teamMember.workPhoneNumber}</span>
                    </a>
                  </div>
                )}

                {data.teamMember.cellPhoneNumber && (
                  <div>
                    <a
                      href={`tel:${data.teamMember.cellPhoneNumber
                        .replace(/[()]/g, "")
                        .replace(/ /g, "-")}`}
                      className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                    >
                      <img src={data.mobile.publicURL} alt="Cell Phone" />
                      <span>{data.teamMember.cellPhoneNumber}</span>
                    </a>
                  </div>
                )}

                {data.teamMember.emailAddress && (
                  <div>
                    <a
                      href={`mailto:${data.teamMember.emailAddress}`}
                      className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                    >
                      <img src={data.email.publicURL} alt="Email" />
                      <span>{data.teamMember.emailAddress}</span>
                    </a>
                  </div>
                )}

                {data.teamMember.linkedinProfile && (
                  <div>
                    <a
                      href={`${data.teamMember.linkedinProfile}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                    >
                      <img src={data.linkedin.publicURL} alt="LinkedIn Logo" />
                      <span>{data.teamMember.linkedinProfile}</span>
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className="mb-5 md:mb-14">
                <h1 className="mb-3">{data.teamMember.name}</h1>
                {/* {data.teamMember.title && (
                  <div className="text-sm font-bold uppercase tracking-wide">
                    {data.teamMember.title}
                  </div>
                )} */}
              </div>

              <div className="mb-10 border-b border-gray-300 pb-10 md:hidden">
                {data.teamMember.headshot && (
                  <div className="mb-6">
                    <GatsbyImage
                      image={data.teamMember.headshot.asset.gatsbyImageData}
                      alt={data.teamMember.name}
                      loading="eager"
                    />
                  </div>
                )}
                <div className="space-y-4">
                  {data.teamMember.workPhoneNumber && (
                    <div>
                      <a
                        href={`tel:${data.teamMember.workPhoneNumber
                          .replace(/[()]/g, "")
                          .replace(/ /g, "-")}`}
                        className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                      >
                        <img src={data.phone.publicURL} alt="Phone" />
                        <span>{data.teamMember.workPhoneNumber}</span>
                      </a>
                    </div>
                  )}

                  {data.teamMember.cellPhoneNumber && (
                    <div>
                      <a
                        href={`tel:${data.teamMember.cellPhoneNumber
                          .replace(/[()]/g, "")
                          .replace(/ /g, "-")}`}
                        className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                      >
                        <img src={data.mobile.publicURL} alt="Cell Phone" />
                        <span>{data.teamMember.cellPhoneNumber}</span>
                      </a>
                    </div>
                  )}

                  {data.teamMember.emailAddress && (
                    <div>
                      <a
                        href={`mailto:${data.teamMember.emailAddress}`}
                        className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                      >
                        <img src={data.email.publicURL} alt="Email" />
                        <span>{data.teamMember.emailAddress}</span>
                      </a>
                    </div>
                  )}

                  {data.teamMember.linkedinProfile && (
                    <div>
                      <a
                        href={`${data.teamMember.linkedinProfile}`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center space-x-4 font-normal text-secondary-900 no-underline hover:text-secondary-500"
                      >
                        <img
                          src={data.linkedin.publicURL}
                          alt="LinkedIn logo"
                        />
                        <span>{data.teamMember.linkedinProfile}</span>
                      </a>
                    </div>
                  )}
                </div>
              </div>

              {data.teamMember.bio.length > 0 && (
                <ul className="styled-list-diamond">
                  {data.teamMember.bio.map((item, i) => (
                    <li key={i}>{item.listItem}</li>
                  ))}
                </ul>
              )}
              {data.teamMember._rawBioBlock && (
                <PortableText blocks={data.teamMember._rawBioBlock} />
              )}
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export default Template;
